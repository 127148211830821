
import React from "react"

import Image from "../Image"

export default ({size}) => (
    <Image 
        id="img-builder-preview"
        alt="SignMonkey Sign Builder"
        backgroundColor={"#758279"}
        backgroundPreview={"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAkACgMBEQACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/AP2T8fz/ALSHw+1/4j2kOkeMbRNP8YfEG5Gr+HvHH7O39pSaXbeONWSw1F7+L4+eD/iDdtqemCx1N7bULe28R2NzqsdhfTW8ttNpMXJVjjuepKljaNKm4NRjUwNCtGmnTVOfvynCbbXNOM7qpTqPnhUi4xS+lwGK4ZeFwuEzDhfF5hiU5U6+KwXEmOyyvi3Ur1KtF06cMNioYetBTpYZexTp1KNFRlQ9tUqVX+6/wQ8Q/Eu0+C3whtPGHgfxNd+LbX4X+ALfxRdav4i0XVNVufEUPhTSY9buNT1OPWNVj1HUJtTW5kvb+PVNSS8uWluFv7xZBcSdS2Xp1v8Ard/fr3PmtFor2W1227ebd235vV9TI+J/Xxd/2CNf/kaHs/R/kB9H6B/yAtF/7BOnf+kcNMD/AP/Z"}
        aspectRatio={88}  
        width={390} 
        height={345.8} 
        sizes={[250, 390]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/builder-preview-250.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/builder-preview-390.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/builder-preview-250.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/builder-preview-390.jpg?v25"
        ]}
    />   
)
