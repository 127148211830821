import React, { useEffect, useState, memo } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import queryString from 'query-string'
import { faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons"
import { isMobile } from "react-device-detect";

import TextField from '../components/fields/TextField'

import Container from "../components/layout/Container"
import Layout2Columns from "../components/layout/Layout2Columns"

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import Button from "../components/buttons/Button"
import ProductBox from "../components/ProductBox"
import SignGrid from "../components/SignGrid"
import SignTemplatePreview from "../components/SignTemplatePreview"

import BuiderPreviewImage from "../components/images/builder-preview"

import { devices, storage } from "../settings"
import { postData } from "../services/server"
import { properCaseProductName } from "../utilities/utils"

const Content1 = styled.div`
  border-top: 3px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-image: url(${storage.url}2020/tiles/grey3.jpg); 
  padding: 35px 0px 15px 0px;

  @media ${devices.tablet} {
    margin: 20px 0px 0px 0px;
    padding: 15px 10px;
  }

  @media ${devices.mobile} {
    margin: 20px -10px 0px -10px;
  }
`
const Content2 = styled.div`
  padding: 25px 0px 50px 0px;
  font-size: 125%;

  @media ${devices.tablet} {
    padding: 15px 10px;
  }

`
const SignTemplateIFrame = styled.iframe`
`
class SignTemplateFrame extends React.PureComponent {
    render() {
      const {src,title} = this.props;

      return (
        <SignTemplateIFrame src={src} title={title} width="100%" height="100%" border="0" frameBorder="0" />
      );
    }
}
class TemplateSignGrid extends React.PureComponent {
    render() {
        const {signs, phrase1} = this.props;

        return (
            <SignGrid>
            {signs.map((sign, index) => (
                sign.type == "placeholder" ?
                    <SignTemplateFrame 
                        key={index}
                        src={process.env.GATSBY_BUILDER_BASE_URL + "?uid=" + sign.uid + "&delay=" + (index*2) + "&phrase1=" + escape(phrase1) + "#/preview"} 
                        title={sign.title}
                    />
                :
                    <SignTemplatePreview
                        key={index}
                        sign={sign}
                    />
            ))}
            </SignGrid>
        )
    }
}

const BuilderPage = () => {
    const [builderParams, setBuilderParams] = useState(null);
    const [customPriceFrom, setCustomPriceFrom] = useState("BuilderPrompt");

    const [businessName, setBusinessName] = useState("");    
    const [searching, setSearching] = useState(false);
    const [signs, setSigns] = useState(null);

    useEffect(
        () => {
            const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
            const params = [];
    
            if (urlParams.from && !urlParams.product) {
              params.push("from=" + urlParams.from);
              setCustomPriceFrom(urlParams.from);
            }
            if (urlParams.product) {
              params.push("from=" + properCaseProductName(urlParams.product))              
              params.push("product=" + urlParams.product)
              
              setCustomPriceFrom(properCaseProductName(urlParams.product));
            }
    
            setBuilderParams(params.join("&"));
        }, 
        []
    );

    function onSearchTemplates() {
        setSearching(true)

        postData("/api/signs/templatesearch", { search: businessName },
            (result) => {
                setSigns(result);

                setTimeout(() => {
                    window.scrollTo({
                      top: document.body.scrollTop + 500,
                      left: 0,
                      behavior: 'smooth'
                    }); 
                }, 250)
            },
            (error) => {
                console.log(error);
                alert("Error searching sign templates.")
            },
            () => {
                setSearching(false);
            }
        );
    }

    return (
        <>
        <SEO page="builder" title="Build & Price Your Lit Sign Now" />
        <Page
            padding={0}
            breadcrumb={[
                {title: "Home", url:"/"},
                {title: "Design & Price Sign", url:null},
            ]}
            containerProps={{
                mode: "full"
            }}
          >
            <Container width="800">
                {false && <PageHeader title="Design & Price Sign" />}

                <Layout2Columns>
                    <div>
                        <BuiderPreviewImage />
                    </div>
                    <div>
                        <p className="first" style={{fontSize: "140%"}}>
                            Use our Online Designer to create your own custom sign.  Get
                            your instant Price Now!
                        </p>
                        <Link to={"/build/?" + builderParams}>
                            <Button 
                                label="Design and Price<br />Your Sign Now"
                                rightIcon={faChevronRight}
                                wide
                                size="huge"
                                color="red"
                            />
                        </Link>
                    </div>
                </Layout2Columns>
            </Container>

            <Content1>
                <Container width="800">
                    <ProductBox>
                        <Layout2Columns>
                            <div style={{fontSize: "140%"}}>
                                <p style={{marginTop: "0px"}}>
                                    Would you rather we design it for you or price
                                    an existing design you already have?
                                </p>
                                <p>
                                    We will get it back to you within 24 hours.
                                </p>
                            </div>
                            <div>
                                <Link to={"/sign-price-request/?from=" + customPriceFrom}>
                                    <Button 
                                        label="Submit My Design <br />For Price"
                                        rightIcon={faChevronRight}
                                        wide
                                        size="huge"
                                        color="lightgrey"
                                        style={{
                                            border: "1px solid #555555"
                                        }}
                                    />
                                </Link>
                            </div>
                        </Layout2Columns>
                    </ProductBox>
                </Container>
            </Content1>

            <Content2>
                <Container width="800">
                    <p>Start with one of our Professionally designed customizable sign templates</p>

                    <div>
                        <TextField 
                            value={businessName}
                            wide
                            error={false}
                            id="businessName"
                            name="businessName"
                            label="Business Name"
                            required
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSearchTemplates();
                                }
                            }}
                            onChange={(e) => {
                                setBusinessName(e.target.value);
                            }} 
                        >
                            <Button 
                                label="Search Sign Templates"
                                icon={faSearch}
                                color="yellow"
                                disabled={businessName.length == 0}
                                busy={searching}
                                onClick={onSearchTemplates}
                            />
                        </TextField>
                    </div>

                    { signs && signs.length > 0 ? 
                          <TemplateSignGrid signs={signs} phrase1={businessName} />
                        : signs ? 
                          <p>No signs found.</p>
                        :
                          null
                    }
                </Container>
            </Content2>
        </Page>
        </>
    )
}

export default BuilderPage